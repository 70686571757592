/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$main-font-family: 'Roboto', sans-serif;
$heading-font-family: 'DM Sans', sans-serif;
$main-color: linear-gradient(to right top, #fdb084, #ff9870, #ff7e62, #ff5f59, #ff3656);
$optional-color: #f64c67;
$others-color: #fd9b5f;
$white-color: #ffffff;
$black-color: #202647;
$paragraph-color: #6b6b84;
$font-size: 16px;
$transition: .5s;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
}
a {
    text-decoration: none;
    transition: $transition;
    color: $black-color;

    &:hover, &:focus {
        color: $main-color;
        text-decoration: none;
    }
}
button {
    outline: 0 !important;
}
p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: $paragraph-color;
    font-weight: 400;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
i {
    line-height: normal;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
    display: inline-block;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    font-family: $heading-font-family;
    font-weight: bold;
    text-transform: capitalize;

    &:last-child {
        margin-bottom: 0;
    }
}

/*================================================
Default btn CSS
=================================================*/
.default-btn {
    display: inline-block;
    padding: 12px 35px;
    color: $white-color;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        transition: $transition;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $optional-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    }
    &:hover {
        color: $white-color;

        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
Section Title CSS
=================================================*/
.section-title {
    text-align: center;
    margin-bottom: 45px;

    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h2 {
        font-size: 48px;
        margin: 14px 0 25px 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin: auto;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
    &.text-width {
        max-width: 600px;
        margin: 0 auto 45px;
    }
}
@keyframes MOVE-BG {
    from {
        transform: translateX(0)
    }
    to {
        transform: translateX(85px)
    }
} 

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $main-color;
    top: 0;
    left: 0;

    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
    }
    .box {
        width: 100%;
        height: 100%;
        background: $white-color;
        animation: animate .5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }
    .shadow { 
        width: 100%;
        height: 5px;
        background: $optional-color;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        border-radius: 50%;
        animation: shadow .5s linear infinite;
    }
}
@keyframes loader {
    0% {
        left: -100px
    }
    100% {
        left: 110%;
    }
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1,.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes shadow {
    50% {
        transform: scale(1.2,1);
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.main-responsive-nav {
    display: none;
}
.main-navbar {
    padding-top: 0;

    .navbar {
        transition: $transition;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: auto;

            .nav-item {
                position: relative;
                margin: {
                    left: 14px;
                    right: 14px;
                };
                a {
                    color: $black-color;
                    font: {
                        size: $font-size;
                        weight: 700;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 25px;
                        bottom: 25px;
                    };
                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -4px;
                            right: -3px;
                        };
                    }
                    &:hover, &:focus, &.active {
                        color: $optional-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover, &.active {
                    a {
                        color: $optional-color;
                    }
                }
                .dropdown-menu {
                    border: none;
                    top: 70px;
                    left: 0;
                    z-index: 99;
                    opacity: 0;
                    width: 300px;
                    display: block;
                    border-radius: 0;
                    padding: 10px 0;
                    margin-top: 15px;
                    position: absolute;
                    visibility: hidden;
                    background: $white-color;
                    transition: all 0.4s ease-in-out;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    li {
                        margin: 0;
                        
                        a {
                            padding: 10px 20px;
                            position: relative;
                            display: block;
                            color: $black-color;
                            font: {
                                size: 16.5px;
                                weight: 700;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $optional-color;
                            }
                        }
                        .dropdown-menu {
                            top: 0;
                            opacity: 0;
                            right: -300px;
                            left: auto;
                            margin-top: 15px;
                            visibility: hidden;
                            
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $optional-color;
                                    }
                                }
                                .dropdown-menu {
                                    top: 0;
                                    opacity: 0;
                                    left: 300px;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: $black-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $optional-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            top: 0;
                                            opacity: 0;
                                            left: -300px;
                                            visibility: hidden;
                
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $optional-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    top: 0;
                                                    opacity: 0;
                                                    left: 300px;
                                                    visibility: hidden;
                        
                                                    li {
                                                        a {
                                                            color: $black-color;
                
                                                            &:hover, &:focus, &.active {
                                                                color: $optional-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            top: 0;
                                                            opacity: 0;
                                                            left: -300px;
                                                            visibility: hidden;
                                
                                                            li {
                                                                a {
                                                                    color: $black-color;
                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $optional-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    left: 300px;
                                                                    visibility: hidden;
                                        
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                
                                                                            &:hover, &:focus, &.active {
                                                                                color: $optional-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $optional-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $optional-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $optional-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $optional-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $optional-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $optional-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }
        .others-options {
            .option-item {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
                .cart-btn {
                    a {
                        font-size: 30px;
                        color: $black-color;
                        transition: $transition;
                        position: relative;

                        &:hover {
                            color: $optional-color;
                            transform: translateY(-5%);
                        }
                        span {
                            position: absolute;
                            right: 0;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            border-radius: 50%;
                            background: $optional-color;
                            color: $white-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                .search-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                }
                .close-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    display: none;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    padding: 10px 25px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}
.navbar-area {
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        background: $white-color;
        z-index: 2;
        right: 12%;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 0;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover, &:focus {
                    color: $main-color;
                }
                i {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
}
.others-option-for-responsive {
    display: none;

    .dot-menu {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        z-index: 9991;
        position: absolute;
        right: 60px;
        top: -23px !important;

        .inner {
            display: flex;
            align-items: center;
            height: 30px;

            .circle {
                height: 5px;
                width: 5px;
                border-radius: 100%;
                margin: 0 2px;
                transition: $transition;
                background-color: $black-color;
            }
        }
        &:hover {
            .inner {
                .circle {
                    background-color: $main-color;
                }
            }
        }
    }
    .container {
        position: relative;

        .container {
            position: absolute;
            right: 0;
            top: 10px;
            max-width: 260px;
            background-color: $white-color;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
            margin-left: auto;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            transform: scaleX(0);
            z-index: 2;
            padding: {
                left: 15px;
                right: 15px;
            };
            &.active {
                opacity: 1;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
    .option-inner {
        .others-options {
            .option-item {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
                .cart-btn {
                    a {
                        font-size: 30px;
                        color: $black-color;
                        transition: $transition;
                        position: relative;

                        &:hover {
                            color: $optional-color;
                            transform: translateY(-5%);
                        }
                        span {
                            position: absolute;
                            right: 0;
                            top: 2px;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            border-radius: 50%;
                            background: $optional-color;
                            color: $white-color;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
                .search-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                }
                .close-btn {
                    font-size: 30px;
                    color: $black-color;
                    transition: $transition;
                    display: none;
                    cursor: pointer;

                    &:hover {
                        color: $optional-color;
                        transform: translateY(-5%);
                    }
                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    padding: 10px 25px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {

    .main-responsive-nav {
        display: block; 

        .main-responsive-menu {
            position: relative;

            &.mean-container {
                .mean-nav {
                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: $optional-color;
                                }
                                i {
                                    display: none;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .others-options {
                    display: none !important;
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $black-color;

                    span {
                        background: $black-color;
                        position: relative;
                    }
                }
            }
        }
        .logo {
            position: relative;

            img {
                max-width: 100px !important;
            }
        }
    }
    .navbar-area {
        background-color: $white-color;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .main-navbar {
        display: none;
    }
    .others-option-for-responsive {
        display: block;
    }
    .main-responsive-nav .logo {
        position: relative;
        width: 50%;
    }
    .mean-container .mean-nav {
        margin-top: 40px;
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-item {
    position: relative;
    z-index: 1;
    background-image: url(assets/img/main-banner-bg-1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    height: 630px;
    background-attachment: fixed;

    .container-fluid {
        padding-right: 0;
    }
    &.item-two {
        background-image: url(assets/img/main-banner-bg-2.jpg);
        height: 800px;
        background-attachment: unset;

        .main-banner-image {
            top: 0;

            img {
                animation: border-transform-default 10s linear infinite alternate forwards;
                border-radius: 50%;
            }
        }
    }
    &.item-three {
        background-image: url(assets/img/main-banner-bg-3.jpg);
        height: 800px;
        background-attachment: unset;

        .main-banner-image {
            top: 0;

            img {
                animation: moveLeftBounce 3s linear infinite;
            }
        }
    }
    &.item-four {
        background-image: url(assets/img/main-banner-bg-4.jpg);
        height: 700px;

        .main-banner-image {
            top: 0;

            img {
                animation: border-transform-default 10s linear infinite alternate forwards;
                border-radius: 100%;
            }
        }
    }
}
.main-banner-content {
    max-width: 620px;
    margin-left: auto;

    h1 {
        font-size: 60px;
        margin-bottom: 16px;
        position: relative;
        z-index: 1;
    } 
    p {
        margin-bottom: 0;
    }
    .banner-btn {
        margin-top: 30px;
    }
}
.main-banner-image {
    position: relative;
    top: 40px;
}
.main-banner {
    overflow: hidden;
    position: relative;
}
.main-banner-shape {
    .shape-1 {
        position: absolute;
        left: 10%;
        top: 16%;
        z-index: 1;
        animation: animationFramesOne 20s infinite linear;
        max-width: 50px;
    }
    .shape-2 {
        position: absolute;
        left: 55%;
        top: 30%;
        z-index: 1;
        max-width: 50px;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .shape-3 {
        position: absolute;
        left: 35%;
        bottom: 18%;
        z-index: 1;
        max-width: 50px;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .shape-4 {
        position: absolute;
        left: 8%;
        top: 20%;
        z-index: 1;
        max-width: 50px;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .shape-5 {
        position: absolute;
        left: 5%;
        bottom: 15%;
        z-index: 1;
        max-width: 45px;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-6 {
        position: absolute;
        left: 35%;
        bottom: 5%;
        z-index: 1;
        max-width: 50px;

        img {
            animation-name: rotateMe;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .shape-7 {
        position: absolute;
        left: 45%;
        top: 30%;
        z-index: 1;
        max-width: 30px;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-8 {
        position: absolute;
        right: 20%;
        bottom: 15%;
        z-index: 1;
        max-width: 30px;
        animation: animationFramesOne 20s infinite linear;
    }
    .shape-9 {
        position: absolute;
        left: -20px;
        top: -85px;
        z-index: 1;
        animation: moveLeftBounce 3s linear infinite;
        max-width: 300px;
    }
    .shape-10 {
        position: absolute;
        top: 5%;
        left: 40%;
        z-index: 1;
        animation: rotate3d 4s linear infinite;
    }
    .shape-11 {
        position: absolute;
        left: 5%;
        bottom: 15%;
        z-index: 1;
        max-width: 60px;
        animation: moveScale 3s linear infinite;
    }
    .shape-12 {
        position: absolute;
        bottom: 15%;
        left: 30%;
        z-index: 1;
        animation: moveBounce 5s linear infinite;
    }
    .shape-13 {
        position: absolute;
        bottom: 0;
        right: -5%;
        z-index: 1;
        animation: moveBounce 5s linear infinite;
        max-width: 300px;
    }
    .shape-14 {
        position: absolute;
        top: 15%;
        left: 48%;
        z-index: 1;
        animation: moveBounce 5s linear infinite;
    }
}
.banner-form {
    position: relative;
    margin-top: 30px;

    .form-control {
        display: block;
        width: 100%;
        background-color: #eeeeee;
        border: none;
        height: 70px;
        padding-top: 5px;
        padding-left: 20px;
        border-radius: 5px;
        border: 1px solid #eeeeee;
        outline: 0;
        transition: $transition;
        
        &:focus {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid $optional-color;
        }
        &::placeholder {
            color: $paragraph-color;
        }
    }
    button {
        position: absolute;
        right: 4px;
        top: 4px;
        background: $main-color;
        color: $white-color;
        border: none;
        height: 62px;
        padding: 0 30px;
        border-radius: 10px;
        transition: $transition;
        line-height: 50px;
        text-transform: capitalize;
        cursor: pointer;
        font: {
            size: 16px;
            weight: 400;
        }
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}
@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes border-transform-default {
    0%, to {
        border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
    }
}

/*================================================
Main Slider Area CSS
=================================================*/
.main-slider-item {
    background-color: #fdf1ef;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding-right: 0;
    }
}
.main-slider-content {
    padding-top: 200px;
    padding-bottom: 200px;
    max-width: 600px;
    margin-left: auto;

    h1 {
        font-size: 60px;
        margin-bottom: 16px;
    } 
    .slider-btn {
        margin-top: 25px;
    }
}
.main-slider-image {
    background-image: url(assets/img/main-slider/main-slider-1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;

    &.two {
        background-image: url(assets/img/main-slider/main-slider-2.jpg);
    }
    &.three {
        background-image: url(assets/img/main-slider/main-slider-3.jpg);
    }
    &.four {
        background-image: url(assets/img/main-slider/main-slider-4.jpg);
    }
    &.five {
        background-image: url(assets/img/main-slider/main-slider-5.jpg);
    }
    
}
.home-wrapper-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.main-slider-shape {
    .shape-1 {
        position: absolute;
        left: -20px;
        top: -85px;
        z-index: 1;
        animation: moveLeftBounce 3s linear infinite;
    }
    .shape-2 {
        position: absolute;
        top: 5%;
        left: 40%;
        z-index: 1;
        animation: rotate3d 4s linear infinite;
    }
    .shape-3 {
        position: absolute;
        left: 5%;
        bottom: 15%;
        z-index: 1;
        max-width: 60px;
        animation: moveScale 3s linear infinite;
    }
    .shape-4 {
        position: absolute;
        bottom: 15%;
        left: 30%;
        z-index: 1;
        animation: moveBounce 5s linear infinite;
    }
    .shape-5 {
        position: absolute;
        bottom: 0;
        left: 30%;
        z-index: 1;
        animation: moveLeftBounce 3s linear infinite;
    }
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                outline: 0;
                width: 45px;
                height: 45px;
                line-height: 52px;
                transition: $transition;
                background-color: $white-color;
                color: $optional-color;
                border-radius: 50%;
                opacity: 1;
                visibility: hidden;

                &:hover, &:focus {
                    background-color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: 10px;

                    i {
                        position: relative;
                        top: 1px;
                        left: 2px;
                    }
                }
                i {
                    font-size: 25px;
                    font-weight: bold;
                    position: relative;
                    top: 1px;
                    right: 2px;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    opacity: 1;
                    visibility: visible;
                    left: 20px;
                    transition: $transition;
    
                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                }
            }
        }
    }
}

/*================================================
Features Area CSS
=================================================*/
.single-features {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 35px 20px;
    position: relative;
    z-index: 1;
    transition: $transition;
    border-radius: 5px;
    margin: {
        bottom: 30px;
    };
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        border-radius: 5px;
    }
    .icon {
        margin-bottom: 16px;

        i {
            font-size: 50px;
            color: $optional-color;
            transition: $transition;
            line-height: 1;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
        transition: $transition;

        a {
            color: $black-color;
        }
    }
    p {
        transition: $transition;
        margin-bottom: 0;
    }
    .number {
        position: absolute;
        bottom: 10px;
        right: 10px;

        span {
            font-size: 25px;
            font-weight: 500;
            color: #cbcac9;
            transition: $transition;
        }
    }
    .shape {
        position: absolute;
        bottom: 25px;
        left: 18px;
        z-index: -1;
        transition: $transition;
        opacity: 30%;
    }
    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
        }
        .icon {
            i {
                color: $white-color;
            }
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
        .number {
            span {
                color: $white-color;
            }
        }
        .shape {
            opacity: 30%;
            bottom: 35px;
        }
    }
}
.single-features-box {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 35px 20px;
    position: relative;
    z-index: 1;
    transition: $transition;
    border-radius: 5px;
    text-align: center;
    margin: {
        bottom: 30px;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        background: $main-color;
        z-index: -1;
        transition: $transition;
        border-radius: 5px;
    }
    .icon {
        margin-bottom: 16px;

        i {
            font-size: 30px;
            color: $optional-color;
            transition: $transition;
            line-height: 1;
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            border-radius: 50px;
            background-color: #f6f6f6;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 12px;
        transition: $transition;

        a {
            color: $black-color;
        }
    }
    p {
        transition: $transition;
        margin-bottom: 0;
    }
    &:hover {
        &::before {
            height: 100%;
        }
        .icon {
            i {
                color: $optional-color;
            }
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
    }
    &.top-1 {
        margin-top: 30px;
    }
    &.top-2 {
        margin-top: 0;
    }
    &.top-3 {
        margin-top: 0;
    }
    &.top-4 {
        margin-top: -30px;
    }
}
.features-inner-content {
    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        margin: 14px 0 25px 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 25px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
    p {
        margin-bottom: 0;
    }
    .features-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 16px;
            left: -10px;
            right: -10px;
        }
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 10px;
            padding: {
                left: 10px;
                right: 10px;
            }
            span {
                display: block;
                background-color: #fab0bc;
                padding: 11px 15px 10px;
                z-index: 1;
                position: relative;
                transition: $transition;
                color: $white-color;
                text-transform: capitalize;
                font-weight: 600;
                border-radius: 5px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 100%;
                    background: $main-color;
                    z-index: -1;
                    transition: $transition;
                    border-radius: 5px;
                }
                i {
                    margin-right: 5px;
                    color: $white-color;
                    position: relative;
                    top: -1px;
                }
                &:hover {
                    color: $white-color;

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
    .features-btn {
        margin-top: 30px;
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    .container-fluid {
        padding-left: 0;
    }
}
.about-content {
    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        margin: 14px 0 25px 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 25px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
    strong {
        color: $paragraph-color;
        font-weight: 600;
    }
    p {
        color: $paragraph-color;
        margin: 15px 0 0 0;
    }
    .about-btn {
        margin-top: 25px;
    }
    &.style-width {
        max-width: 630px;
    }
    &.warp {
        padding: 30px 0 30px 0;
        max-width: 630px;
    }
    .about-inner-content {
        margin-top: 25px;
        position: relative;
        padding-left: 45px;
        transition: $transition;

        .icon {
            i {
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background: $others-color;
                color: $white-color;
                text-align: center;
                font-size: 20px;
                border-radius: 30px;
                position: absolute;
                left: 0;
                top: 0;
                transition: $transition;
            }
        }
        h4 {
            font-size: 22px;
            margin-bottom: 12px;
        }
        p {
            margin-top: 0;
        }
        &:hover {
            .icon {
                i {
                    background: $optional-color;
                }
            }
        }
    }
}
.about-image-warp {
    background-image: url(assets/img/about/about-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;

    &.image-two {
        background-image: url(assets/img/about/about-3.jpg);
    }
    &.image-three {
        // background-image: url(assets/img/about/about-5.jpg);
    }

    .video-btn {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 70px;
        background: $main-color;
        border-radius: 50%;
        color: $white-color;
        position: absolute;
        z-index: 1;
        right: 30px;
        bottom: 50px;
        text-align: center;

        i {
            font-size: 50px;
            position: relative;
            top: 4px;
            left: 2px;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $optional-color;
            animation: ripple 1s linear 1s infinite;
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid $optional-color;
            animation: ripple 1s linear infinite;
        }
        &:hover, .video-content .video-btn:focus {
            background-color: $white-color;
            color: $main-color;
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.75);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-fff5ee {
        background-color: #fff5ee;
    }
    &.bg-fafafa {
        background-color: #fafafa;
    }
    &.bg-fafafa {
        background-color: #fafafa;
    }
    &.bg-0f051e {
        background-color: #0f051e;

        .section-title {
            h2 {
                color: $white-color;
            }
        }
    }
}
.single-services {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    margin-bottom: 30px;
    text-align: center;
    padding: 35px 30px 75px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        bottom: -88%;
        left: 0;
        width: 120%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: $main-color;
        transform: rotate(-14deg);
    }
    .image {
        margin-bottom: 25px;
    }
    h3 {
        font-size: 25px;
        margin-bottom: 16px;

        a {
            color: $black-color;
        }
    }
    .services-btn {
        color: $optional-color;
        font-weight: 500;
        transition: $transition;

        i {
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .services-btn {
            letter-spacing: 1px;
        }
        h3 {
            a {
                color: $optional-color;
            }
        }
    }
    .icon {
        margin-bottom: 20px;

        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background: $main-color;
            color: $white-color;
            font-size: 45px;
            border-radius: 50px;
        }
    }
}
.single-services-item {
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;

    .content {
        background-color: $white-color;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        padding: 35px 20px;
        max-width: 350px;
        margin: -50px auto 0;
        position: relative;
        z-index: 1;
        transition: $transition;
        border-radius: 5px;

        h3 {
            margin-bottom: 10px;
            font-size: 25px;
            transition: $transition;

            a {
                color: $black-color;
            }
        }
        span {
            font-weight: bold;
            transition: $transition;
            color: $paragraph-color;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 0;
            background: $main-color;
            z-index: -1;
            transition: $transition;
            border-radius: 5px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    color: $white-color;
                }
            }
            span {
                color: $white-color;
            }
        }
    }
}
.single-services-box {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    margin-bottom: 30px;
    text-align: center;
    padding: 35px 30px 45px 30px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        bottom: -92%;
        left: 0;
        width: 120%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: $main-color;
        transform: rotate(-7deg);
    }
    .icon {
        margin-bottom: 20px;
        transition: $transition;

        i {
            display: inline-block;
            height: 120px;
            width: 120px;
            line-height: 120px;
            background: $main-color;
            color: $white-color;
            font-size: 45px;
            border-radius: 50px;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 16px;

        a {
            color: $black-color;
        }
    }
    .services-btn {
        color: $optional-color;
        font-weight: 500;
        transition: $transition;

        i {
            position: relative;
            top: 1px;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .services-btn {
            letter-spacing: 1px;
        }
        h3 {
            a {
                color: $optional-color;
            }
        }
        .icon {
            animation: bounce 2s
        }
    }
}

/*================================================
Default Shape CSS
=================================================*/
.shape1 {
    position: absolute;
    left: 5%;
    top: 10%;
    z-index: 1;
    animation: moveLeftBounce 3s linear infinite;
    z-index: -1;
}
.shape2 {
    position: absolute;
    left: 2%;
    bottom: 30%;
    z-index: 1;
    animation: animationFramesOne 20s infinite linear;
    z-index: -1;
}
.shape3 {
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: 1;
    animation: animationFramesOne 20s infinite linear;
    z-index: -1;
}
.shape4 {
    position: absolute;
    right: 5%;
    bottom: 30%;
    z-index: 1;
    animation: moveLeftBounce 3s linear infinite;
    z-index: -1;
}

/*================================================
Digital Experience Area CSS
=================================================*/
.tab {
    .tabs_item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}
.digital-experience-tab {
    .tabs {
        text-align: center;
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 50px;

        li {
            text-align: center;
            display: inline-block;
            list-style: none;
            background-color: $white-color;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            margin-right: 15px;
            transition: $transition;
            flex: 0 0 16.6666666667%;
            max-width: 15.6666666667%;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                color: $black-color;
                padding: 20px 25px;
                position: relative;
                z-index: 1;
                overflow: hidden;
                transition: $transition;
                border-radius: 5px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: $main-color;
                    transition: $transition;
                    border-radius: 5px;
                    opacity: 0;
                    visibility: hidden;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: $main-color;
                    transition: $transition;
                    border-radius: 5px;
                    opacity: 0;
                    visibility: hidden;
                }
                font: {
                    size: 16px;
                    weight: 500;
                }
                span {
                    display: block;
                    margin-top: 0;

                    i {
                        display: block;
                        font-size: 35px;
                        margin-bottom: 8px;
                        color: $main-color;
                    }
                }
            }
            &.current, &:hover {
                a {
                    color: $white-color;

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                    span {
                        i {
                            color: $white-color;
                        }
                    }
                }
            }
            &.bg-5cbd12 {
                i {
                    color: #5cbd12;
                }
            }
            &.bg-e2851b {
                i {
                    color: #e2851b;
                }
            }
            &.bg-04b893 {
                i {
                    color: #04b893;
                }
            }
            &.bg-785eda {
                i {
                    color: #785eda;
                }
            }
            &.bg-d0465a {
                i {
                    color: #d0465a;
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            .digital-experience-content {
                h3 {
                    font-size: 30px;
                    margin-bottom: 12px;
                }
                p {
                    margin-bottom: 0;
                }
                .experience-inner-content {
                    margin-top: 30px;
                    position: relative;
                    padding-left: 45px;
                    transition: $transition;

                    .icon {
                        i {
                            display: inline-block;
                            height: 30px;
                            width: 30px;
                            line-height: 30px;
                            background: $others-color;
                            color: $white-color;
                            text-align: center;
                            font-size: 20px;
                            border-radius: 30px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            transition: $transition;
                        }
                    }
                    h3 {
                        font-size: 22px;
                        margin-bottom: 12px;
                    }
                    &:hover {
                        .icon {
                            i {
                                background: $optional-color;
                            }
                        }
                    }
                }
            }
            .digital-experience-image {
                text-align: right;
            }
        }
    }
}

/*================================================
Fun Facts Area CSS
=================================================*/
.single-fun-fact {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 45px 30px 45px 115px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $main-color;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
        border-radius: 10px;
    }
    h3 {
        margin-bottom: 0;
        line-height: 1;
        font-size: 50px;
        transition: $transition;
    }
    p {
        font-size: 16px;
        font-weight: 500;
        transition: $transition;
    }
    .icon {
        position: absolute;
        left: 30px;
        top: 50px;

        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            background-color: #fbe4d4;
            color: $optional-color;
            font-size: 30px;
            border-radius: 50px;
            transition: $transition;
        }
    }
    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .icon {
            i {
                background-color: $white-color;
                color: $optional-color;
            }
        }
    }
}
.single-fun-fact-box {
    margin-bottom: 30px;
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 35px 30px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background: $main-color;
        z-index: -1;
        transition: $transition;
    }
    h3 {
        margin-bottom: 0;
        line-height: 1;
        font-size: 50px;
        transition: $transition;
    }
    p {
        font-size: 16px;
        font-weight: 500;
        transition: $transition;
    }
    .icon {
        margin-bottom: 25px;

        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            background-color: #fbe4d4;
            color: $optional-color;
            font-size: 30px;
            border-radius: 50px;
            transition: $transition;
        }
    }
    &:hover {
        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .icon {
            i {
                background-color: $white-color;
                color: $optional-color;
            }
        }
    }
}

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-fff5ee {
        background-color: #fff5ee;
    }
    &.bg-f1f1f1 {
        background-color: #f1f1f1;
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}
.single-portfolio {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $main-color;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
    }
    .portfolio-content {
        position: absolute;
        left: 0;
        top: 55%;
        transform: translateY(-55%);
        text-align: center;
        right: 0;
        margin-top: 0;
        font-size: 20px;
        opacity: 0;
        visibility: hidden;
        transition: $transition;

        i {
            display: inline-block;
            height: 35px;
            width: 35px;
            line-height: 38px;
            background-color: $white-color;
            color: $optional-color;
            border-radius: 50px;
            font-size: 22px;
            transition: $transition;

            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
        h3 {
            font-size: 25px;
            transition: $transition;
            margin-top: 12px;
            margin-bottom: 0;

            a {
                color: $white-color;
            }
        }
    }
    &:hover, &:focus {
        transform: translateY(-5px);

        &::before {
            opacity: 0.9;
            visibility: visible;
        }
        .portfolio-content {
            opacity: 1;
            visibility: visible;
            margin-top: -15px;
        }
    }
}
.single-portfolio-item {
    margin-bottom: 30px;
    text-align: center;
    transition: $transition;
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

    .portfolio-content {
        padding: 35px 20px;
        position: relative;
        z-index: 1;
        transition: $transition;

        h3 {
            margin-bottom: 10px;
            font-size: 25px;
            transition: $transition;

            a {
                color: $black-color;
            }
        }
        span {
            font-weight: bold;
            transition: $transition;
            color: $paragraph-color;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0;
            background: $main-color;
            z-index: -1;
            transition: $transition;
        }
    }
    &:hover {
        transform: translateY(-10px);

        .portfolio-content {
            &::before {
                height: 100%;
            }
            h3 {
                a {
                    color: $white-color;
                }
            }
            span {
                color: $white-color;
            }
        }
    }
}
.portfolio-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $optional-color;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: $main-color;
                        border-radius: 50%;
                        margin: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 10px;
            line-height: 0;
        }
    }
}

/*================================================
Testimonial Area CSS
=================================================*/
.testimonial-area {
   // background-image: url(assets/img/testimonial/testimonial-bg-1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;

    &.testimonial-two {
        background-image: unset;

        .testimonial-image  {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                height: 90%;
                width: 100%;
                border-left: 30px solid $others-color;
                border-bottom: 30px solid $others-color;
                left: 0;
                bottom: 0;
            }
        }
        .testimonial-content {
            h2 {
                color: $black-color;
            }
        }
        .testimonial-inner-content {
            p {
                color: $paragraph-color;
            }
            .info-text {
                h4 {
                    color: $black-color;
                }
                span {
                    color: $paragraph-color;
                }
            }
        }
    }
}
.testimonial-image {
   // background-image: url(assets/img/testimonial/testimonial-1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &.image-two {
     //   background-image: url(assets/img/testimonial/testimonial-2.jpg);
    }
    &.image-three {
     //   background-image: url(assets/img/testimonial/testimonial-3.jpg);
    }
}
.testimonial-content {
    margin-top: 20px;
    margin-bottom: 45px;

    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h2 {
        font-size: 48px;
        color: $white-color;
        margin: 14px 0 25px 0;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $main-color;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white-color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
}
.testimonial-inner-content {
    padding-top: 190px;
    padding-bottom: 20px;

    .icon {
        margin-bottom: 30px;

        i {
            display: inline-block;
            height: 100px;
            width: 100px;
            line-height: 100px;
            font-size: 60px;
            position: relative;
            z-index: 1;
            border-radius: 50%;
            background: $main-color;
            color: $white-color;
            text-align: center;
        }
    }
    p {
        color: $white-color;
        font-style: italic;
        font-size: 18px;
        margin-bottom: 0;
    }
    .info-text {
        margin-top: 30px;

        h4 {
            font-size: 25px;
            margin-bottom: 12px;
            color: $white-color;
        }
        span {
            color: $white-color;
        }
    }
}
.testimonial-slider {
    margin-top: -190px;

    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -10px;
                top: 40%;
                transform: translateY(-40%);
                margin: 0;
                outline: 0;
                width: 45px;
                height: 45px;
                line-height: 52px;
                transition: $transition;
                background: $optional-color;
                color: $white-color;
                border-radius: 50%;
                opacity: 0;
                visibility: hidden;

                &:hover, &:focus {
                    background-color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: -10px;

                    i {
                        position: relative;
                        top: 1px;
                        left: 2px;
                    }
                }
                i {
                    font-size: 25px;
                    font-weight: bold;
                    position: relative;
                    top: 1px;
                    right: 2px;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    opacity: 1;
                    visibility: visible;
                    left: -20px;
                    transition: $transition;
    
                    &.owl-next {
                        left: auto;
                        right: -20px;
                    }
                }
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.single-blog {
    overflow: hidden;
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;

    .image {
        img {
            transition: $transition;
            border-radius: 10px 10px 0 0;
        }
    }
    .content {
        padding: 25px 20px;
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        position: relative;
        z-index: 9;

        span {
            color: $optional-color;
            font-weight: bold;
        }
        h3 {
            font-size: 25px;
            line-height: 1.5;
            margin-top: 12px;
            margin-bottom: 18px;
            transition: $transition;

            a {
                color: $black-color;
            }
        }
        .blog-btn {
            font-weight: 600;
            color: $optional-color;
            transition: $transition;

            i {
                position: relative;
                top: 2px;
            }
        }
    }
    &:hover {
        .content {
            .blog-btn {
                letter-spacing: 1px;
            }
        }
        .image {
            img {
                transform: rotate(8deg) scale(1.3);
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
}
.single-team {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    .image {
        position: relative;
        transition: $transition;

        img {
            border-radius: 10px;
        }
        .social {
            position: absolute;
            top: 20px;
            right: 20px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                transform: perspective(1000px) rotateY(90deg);
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: $white-color;
                    box-shadow: 1px 20px 30px rgba(0, 0, 0, 0.07);
                    text-align: center;
                    position: relative;
                    font-size: 22px;
                    color: $main-color;
                    border-radius: 30px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background: $optional-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
                &:nth-child(3) {
                    transition-delay: 0.4s;
                }
                &:nth-child(4) {
                    transition-delay: 0.5s;
                }
            }
        }
        .content {
            background-color: $white-color;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            padding: 35px 20px;
            max-width: 265px;
            margin: -50px auto 0;
            position: relative;
            z-index: 1;
            transition: $transition;
            border-radius: 5px;

            h3 {
                margin-bottom: 10px;
                font-size: 25px;
                transition: $transition;

                a {
                    color: $black-color;
                }
            }
            span {
                font-weight: bold;
                transition: $transition;
                color: $paragraph-color;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 0;
                background: $main-color;
                z-index: -1;
                transition: $transition;
                border-radius: 5px;
            }
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
            .content {
                &::before {
                    height: 100%;
                }
                h3 {
                    color: $white-color;
                }
                span {
                    color: $white-color;
                }
            }
        }
    }
}
.team-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $optional-color;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: $main-color;
                        border-radius: 50%;
                        margin: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                    }
                }
                &:hover, &.active {
                    span {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 10px;
            line-height: 0;
        }
    }
}
.single-team-box {
    margin-bottom: 30px;
    text-align: center;

    .image {
        position: relative;
        transition: $transition;
        overflow: hidden;

        .social {
            padding-left: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            };
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 1px;
                    right: 1px;
                };
                a {
                    display: block;
                    width: 33px;
                    height: 33px;
                    background-color: #f4f5fe;
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: $black-color;
                    border-radius: 50px;
                    transition: $transition;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.1s;
                }
                &:nth-child(2) {
                    transition-delay: 0.2s;
                }
                &:nth-child(3) {
                    transition-delay: 0.3s;
                }
                &:nth-child(4) {
                    transition-delay: 0.4s;
                }
            }
        }
        img {
            border-radius: 5px;
            transition: $transition;
        }
    }
    .content {
        margin-top: 20px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 600;
            };
        }
        span {
            display: block;
            color: $optional-color;
            font-size: 14px;
            margin-top: 6px;
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
            img {
                transform: rotate(-5deg) scale(1.1);
            }
        }
    }
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
    .row {
        max-width: 1000px;
        margin: auto;
    }
}
.single-pricing-box {
    margin-bottom: 30px;
    padding: 30px;
    transition: $transition;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    background-color: $white-color;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: $main-color;
        z-index: -1;
        transition: $transition;
        border-radius: 5px;
    }
    &.top-1 {
        margin-top: 35px;
    }
    &.top-2 {
        margin-top: 35px;
    }
    .pricing-header {
        h3 {
            margin-bottom: 20px;
            transition: $transition;
            font: {
                size: 25px;
            }
        }
    }
    .price {
        font-size: 36px;
        font-weight: bold;
        color: $optional-color;
        margin-bottom: 30px;
        line-height: 1;
        transition: $transition;

        span {
            font-size: $font-size;
        }
    }
    .pricing-features {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;

        li {
            margin-bottom: 12px;
            position: relative;
            text-transform: capitalize;
            font-weight: 400;
            font-size: 15px;
            transition: $transition;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                margin-right: 5px;
                color: $others-color;
                font-size: 15px;
                transition: $transition;
            }
        }
    }
    .pricing-btn {
        margin-top: 25px;
        position: relative;
        z-index: 1;

        .default-btn {
            border-radius: 30px;
        }
    }
    .pricing-shape {
        position: absolute;
        right: -30px;
        bottom: -30px;
        z-index: -1;
        transition: $transition;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        transform: translateY(-5px);

        &::before {
            width: 100%;
        }
        .pricing-header {
            h3 {
                color: $white-color;
            }
        }
        .price {
            color: $white-color;
        }
        .pricing-features {
            li {
                color: $white-color;

                i {
                    color: $white-color;
                }
            }
        }
        .pricing-btn {
            .default-btn {
                background-color: $white-color;
                color: $optional-color;
            }
        }
        .pricing-shape {
            opacity: 1;
            visibility: visible;
            right: -30px;
            bottom: -30px;
        }
    }
}
.pricing-tab {
    text-align: center;

    .tabs {
        padding-left: 0;
        list-style-type: none;
        display: inline-block;
        position: relative;
        border-radius: 35px;
        border: 1px solid $optional-color;
        margin-bottom: 40px;

        li {
            display: inline-block;

            a {
                background-color: $white-color;
                padding: 12px 30px;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                border-radius: 30px;

                &:hover {
                    color: $white-color;
                    background-color: $optional-color;
                }          
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $optional-color;
                }
            }
        }
    }
}

/*================================================
Industries Area CSS
=================================================*/
.industries-area {
    background: linear-gradient(to right top, #ffbac3, #ffc4c3, #ffcec5, #ffd7ca, #fee1d2);
}
.single-industries-box {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 0 50px 0 50px;
    background-color: #f7f9fc;
    overflow: hidden;
    transition: $transition;
    padding: {
        left: 25px;
        right: 48px;
        top: 35px;
        bottom: 35px;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: $main-color;
        z-index: -1;
        content: '';
        transition: $transition;
    }
    i {
        position: absolute;
        right: 10px;
        top: 50%;
        transition: $transition;
        color: #ffa6b3;
        transform: translateY(-50%);
        font-size: 50px;

        &::before {
            transition: $transition;
            font-size: 50px;
        }
    }
    h3 {
        margin-bottom: 0;
        transition: $transition;
        font: {
            size: 18px;
            weight: 700;
        }
    }
    a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
    &:hover {
        padding-left: 74px;

        &::before {
            width: 100%;
        }
        i {
            left: 25px;
            color: $white-color;
            font-size: 35px;

            &::before {
                font-size: 35px;
            }
        }
        h3 {
            color: $white-color;
        }
    }
}

/*================================================
Client Area CSS
=================================================*/
.client-area {
    background-image: url(assets/img/client-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-attachment: fixed;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #000000;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        opacity: .70;
    }
    .section-title {
        span {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
}
.client-slider {
    .client-item {
        text-align: center;

        p {
            font-size: 18px;
            color: $white-color;
            font-style: italic;
            max-width: 1000px;
            margin: auto;
        }
        .client-info {
            margin-top: 30px;

            img {
                display: inline-block;
                width: 100px;
                height: 100px;
                border-radius: 50px;
                margin-bottom: 20px;
            }
            h3 {
                font-size: 25px;
                margin-bottom: 10px;
                color: $optional-color;
            }
            span {
                color: $white-color;
            }
        }
    }

    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                position: absolute;
                left: -10px;
                top: 35%;
                transform: translateY(-35%);
                margin: 0;
                outline: 0;
                width: 45px;
                height: 45px;
                line-height: 52px;
                transition: $transition;
                background: $optional-color;
                color: $white-color;
                border-radius: 50%;

                &:hover, &:focus {
                    background-color: $black-color;
                }
                &.owl-next {
                    left: auto;
                    right: -10px;

                    i {
                        position: relative;
                        top: 1px;
                        left: 2px;
                    }
                }
                i {
                    font-size: 25px;
                    font-weight: bold;
                    position: relative;
                    top: 1px;
                    right: 2px;
                }
            }
        }
        &:hover {
            .owl-nav {
                [class*=owl-] {
                    left: -20px;
                    transition: $transition;
    
                    &.owl-next {
                        left: auto;
                        right: -20px;
                    }
                }
            }
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    position: relative;
    z-index: 1;
    background-image: url(assets/img/subscribe-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        height: 100%;
        background: $main-color;
        opacity: 0.9;
    }
}
.subscribe-content {
    text-align: center;
    max-width: 835px;
    margin: auto;

    span {
        color: $white-color;
        font-weight: bold;
    }
    h2 {
        color: $white-color;
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 48px;
    }
    .newsletter-form {
        position: relative;
        margin-top: 35px;

        .input-newsletter {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 65px;
            padding-left: 25px;
            border-radius: 10px;
            padding-top: 0;
            outline: 0;
            color: $black-color;
        }
        button {
            position: absolute;
            right: 5px;
            top: 5px;
            background: $main-color;
            color: $white-color;
            border: none;
            height: 55px;
            padding: 0 40px;
            border-radius: 10px;
            transition: $transition;
            line-height: 55px;
            font-size: 15px;
            font-weight: 700;
        }
    }
}

/*================================================
Quote Area CSS
=================================================*/
.quote-area  {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.quote-form {
    background-color: $white-color;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    padding: 50px 40px;

    .content {
        text-align: center;
        margin: {
            bottom: 20px;
        };
        h3 {
            font-size: 36px;
            margin: {
                bottom: 0;
            };
        }
    }
    .form-group {
        .form-control {
            padding: 15px 20px;
            border-bottom: 1px solid #eeeeee !important;
            color: #5d5d5d;
            border-radius: 0;
            background-color: transparent;
            font-size: 14px;
            font-weight: 400;
            height: 60px;
            border: none;
            padding-left: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
    textarea {
        height: 130px !important;
    }
    .nice-select {
        height: 60px;
        width: 100%;
        line-height: 48px;
        margin-bottom: 20px;
        padding-left: 0;
        border-bottom: 1px solid #eeeeee !important;
        color: #5d5d5d;
        border-radius: 0;
        background-color: transparent;
        padding-top: 5px;
        border: none;

        .list {
            background-color: $white-color;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding: {
                top: 10px;
                bottom: 10px;
            }
            .option {
                transition: .6s;
                color: $black-color;
                padding: {
                    left: 20px;
                    right: 20px;
                }
                &:hover {
                    background: $optional-color !important;
                    color: $white-color;
                }
                &.selected {
                    background-color: transparent;
                    font-weight: 600;
                }
            }
        }
        &::after {
            height: 8px;
            width: 8px;
            border-color: $others-color;
            right: 5px;
        }
    }
    .quote-btn {
        margin-top: 25px;

        .default-btn {
            display:block;
            padding: 12px;
            width: 100%;
            background-color: $optional-color;
            border: none;
            cursor: pointer;
            transition: $transition;

            &:hover {
                background-color: $others-color;
            }
        }
    }
}
.quote-shape {
    .shape-1 {
        position: absolute;
        top: 5%;
        left: 42%;
        z-index: -1;
        animation: moveLeftBounce 3s linear infinite;
    }
    .shape-2 {
        position: absolute;
        left: 2%;
        bottom: 8%;
        z-index: -1;
        animation: moveBounce 5s linear infinite;
    }
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
    background-color: #fafafa;
    position: relative;
    z-index: 1;
}
.overview-content {
    span {
        color: $others-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        font-size: 48px;
        margin-top: 12px;
        margin-bottom: 16px;
    }
    p {
        margin-bottom: 0;
    }
    .overview-btn {
        margin-top: 30px;
    }
}
.overview-image {
    img {
        border-radius: 100%;
        animation: border-transform-default 10s linear infinite alternate forwards;
    }
}

/*================================================
Page Banner CSS
=================================================*/
.page-banner-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(assets/img/page-banner/page-banner-1.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
        attachment: fixed;
    };
    padding: {
        top: 140px;
        bottom: 140px;
    };
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        background-color: #000000;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        opacity: .60;
    }
    &.item-bg1 {
        background-image: url(assets/img/page-banner/page-banner-2.jpg);
    }
    &.item-bg2 {
        background-image: url(assets/img/page-banner/page-banner-3.jpg);
    }
    &.item-bg3 {
        background-image: url(assets/img/page-banner/page-banner-4.jpg);
    }
    &.item-bg4 {
        background-image: url(assets/img/page-banner/page-banner-5.jpg);
    }
}
.page-banner-content {
    text-align: center;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        margin-bottom: 20px;
        font-size: 45px;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            font-weight: 600;
            color: $optional-color;
            position: relative;
            margin: {
                left: 10px;
                right: 10px;
            };
            &::before {
                content: '';
                position: absolute;
                left: -10px;
                top: 7px;
                height: 12px;
                width: 1px;
                background: $main-color;
            }
            &:first-child {
                margin-left: 0;

                &::before {
                    display: none;
                }
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                color: $white-color;
            }
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-area {
    background-color: #fafafa;
}
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
            bottom: 0;
        };
        .accordion-item {
            display: block;
            background: #f3f3f3;
            margin-bottom: 10px;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion-title {
            padding: 25px 40px 20px 20px;
            color: $black-color;
            position: relative;
            display: block;
            text-transform: capitalize;
            font: {
                size: 20px;
                weight: 600;
            };
            i {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: $black-color;
                font-size: 20px;
                transition: $transition;
            }
            &.active {
                i {
                    &::before {
                        content: "\eace";
                        color: $optional-color;
                    }
                }
            }
        }
        .accordion-content {
            display: none;
            position: relative;
            padding: 18px 20px;
            border-top: 1px solid $optional-color;
            color: $paragraph-color;

            &.show {
                display: block;
            }
        }
    }
}
.faq-contact-form {
    #contactForm {
        .form-group {
            .form-control {
                height: 60px;
                padding: 0 0 0 15px;
                line-height: initial;
                color: $black-color;
                background-color: transparent;
                border: 1px solid #f1f1f1;
                border-radius: 5px;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 400;
                };
                &:focus {
                    border-color: $optional-color;
                }
            }
            textarea.form-control {
                height: auto;
                padding: 15px 0 0 15px;
            }
        }
        .send-btn {
            margin-top: 15px;

            .default-btn {
                border: none;
                position: relative;
                z-index: 1;
            }
        }
    }
}

/*================================================
Login Area CSS
=================================================*/
.login-form {
    padding: 40px 50px;
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 0 10px rgba(51,51,51,.1);

    h2 {
        margin-bottom: 30px;
        font: {
            size: 35px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: $paragraph-color;
                font-weight: 400;
                font-size: $font-size;
            }
            .form-control {
                height: 55px;
                padding: 0 0 0 15px;
                line-height: initial;
                color: $black-color;
                background-color: transparent !important;
                border: 1px solid #f1f1f1;
                border-radius: 5px;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 400;
                };
                &:focus {
                    border-color: $optional-color;
                }
            }
        }
        .lost-your-password {
            text-align: right;

            a {
                display: inline-block;
                position: relative;
                font-weight: 500;
                font-size: 15px;
                color: $paragraph-color;

                &::before {
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    transition: $transition;
                    background-color: #eeeeee;
                }
                &::after {
                    width: 0;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    bottom: 0;
                    content: '';
                    background-color: $optional-color;
                }
                &:hover {
                    &::before {
                        width: 0;
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
        .form-check {
            font-weight: 500;
            font-size: 15px;
            color: $paragraph-color;
        }
        .form-check-input {
            position: absolute;
            margin-top: 4px;
            margin-left: -18px;
        }
        button {
            position: relative;
            z-index: 1;
            border: none;
            margin-top: 25px;
            padding: 12px 30px;
            width: 100%;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}

/*================================================
Register Area CSS
=================================================*/
.register-form {
    padding: 40px 50px;
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 0 10px rgba(51,51,51,.1);

    h2 {
        margin-bottom: 30px;
        font: {
            size: 35px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: $paragraph-color;
                font-weight: 400;
                font-size: $font-size;
            }
            .form-control {
                height: 55px;
                padding: 0 0 0 15px;
                line-height: initial;
                color: $black-color;
                background-color: transparent !important;
                border: 1px solid #f1f1f1;
                border-radius: 5px;
                box-shadow: unset !important;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 400;
                };
                &:focus {
                    border-color: $optional-color;
                }
            }
        }
        .description {
            font-style: italic;
            font-size: 13.5px;
            margin: {
                top: -10px;
                bottom: 0;
            };
        }
        button {
            position: relative;
            z-index: 1;
            border: none;
            margin-top: 25px;
            padding: 12px 30px;
            width: 100%;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}

/*================================================
Forgot Password CSS
=================================================*/
.forgot-password-form {
    background: $white-color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 750px;
    margin: auto;

    .forgot-password-in-title {
        margin-bottom: 30px;

        h3 {
            font-size: 30px;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 30px;
        }
    }
    form {
        .form-control {
            height: 60px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $black-color;
            padding-left: 20px;
            background: transparent;

            &:focus {
                border-color: $optional-color;
                outline: 0;
                box-shadow: none;
            }
            &::placeholder {
                color: $paragraph-color;
            }
        }
        .send-btn {
            margin-top: 25px;

            .default-btn {
                cursor: pointer;
                border: none;
                position: relative;
                z-index: 1;
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    border-top: 1px solid #eeeeee;
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 750px;
    
    h3 {
        font-size: 38px;
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 25px;
    } 
}

/*================================================
Privacy Area CSS
=================================================*/
.privacy-content {
    h3 {
        font-size: 22px;
        margin: 0 0 16px 0;
    }    
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: $paragraph-color;
            line-height: 1.8;
            font: {
                weight: 400;
            };
        }
    }
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content {
    h3 {
        font-size: 22px;
        margin: 0 0 16px 0;
    }    
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: $paragraph-color;
            line-height: 1.8;
            font: {
                weight: 400;
            };
        }
    }
}

/*================================================
Contact Info Area CSS
=================================================*/
.contact-info-box {
    text-align: center;
    border-radius: 5px;
    transition: $transition;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background: $main-color;
        z-index: -1;
        transition: $transition;
        border-radius: 5px;
    }
    .icon {
        display: inline-block;
        width: 70px;
        height: 70px;
        line-height: 70px;
        background: #f5f5f5;
        border-radius: 50%;
        font-size: 35px;
        color: $optional-color;
        transition: $transition;
        margin-bottom: 12px;
        position: relative;

        i {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }
    h3 {
        margin-bottom: 12px;
        transition: $transition;
        font: {
            size: 23px;
            weight: 700;
        };
    }
    p {
        margin-bottom: 2px;
        transition: $transition;

        a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
                color: $main-color;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:hover {
        &::before {
            height: 100%;
        }
        h3 {
            color: $white-color;
        }
        p {
            a {
                color: $white-color;
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-form {
    padding: 30px 25px;
    border-radius: 10px;
    background-color: $white-color;
    box-shadow: 0 0 10px rgba(51,51,51,.1);
    max-width: 900px;
    margin: auto;

    .form-group {
        .form-control {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            background-color: $white-color;
            border: 1px solid #f1f1f1;
            border-radius: 3px;
            box-shadow: none;
            padding: 0 12px;
            transition: $transition;

            &:focus {
                outline: 0;
                background-color: $white-color;
                border-color: $optional-color;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding: 8px 12px;
            line-height: 1.5rem;
        }
        label {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 5px;
        }
    }
    #msgSubmit {
        margin: 0;
        font-size: 1.3rem;

        &.text-danger, &.text-success {
            margin-top: 15px;
            font-size: 20px;
        }
    }
    .default-btn {
        position: relative;
        z-index: 1;
        border: none;
        margin-top: 10px;
    }
}
.contact-form-inner {
    .form-group {
        .form-control {
            display: block;
            width: 100%;
            height: 50px;
            outline: 0;
            background-color: $white-color;
            border: 1px solid #f1f1f1;
            border-radius: 3px;
            box-shadow: none;
            padding: 0 12px;
            transition: $transition;
            font-size: 15px;

            &:focus {
                outline: 0;
                background-color: $white-color;
                border-color: $optional-color;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding: 8px 12px;
            line-height: 1.5rem;
        }
    }
    .default-btn {
        position: relative;
        z-index: 1;
        border: none;
        margin-top: 10px;
    }
}
.general-contact-info {
    text-align: center;
    margin-top: 60px;

    .contact-info-content {
        h3 {
            font-size: 25px;
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 0;
            font-size: 18px;
            text-transform: lowercase;

            a {
                display: inline-block;
                color: $optional-color;
                
                &:hover {
                    color: $others-color;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                margin-top: 8px;
                margin-bottom: 10px;
                font-size: 18px;
                font-weight: 500;
            }
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            margin-top: 30px;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin: 0 2px;

                a {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 36px;
                    border: 1px solid #dadada;
                    border-radius: 50%;
                    color: #aba5a5;

                    i {
                        font-size: 18px;
                    }
                    &:hover {
                        color: $white-color;
                        border-color: $optional-color;
                        background-color: $optional-color;
                    }
                }
            }
        }
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-desc {
    h3 {
        margin-top: 30px;
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
    }
    p {
        margin-bottom: 0;
    }
    .services-details-features {
        margin-top: 30px;
        margin-bottom: 30px;

        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 0;
            };
            li {
                position: relative;
                color: $black-color;
                margin-bottom: 15px;
                padding-left: 25px;
                line-height: 1.8;
                font-weight: 500;
                font-size: $font-size;
    
                i {
                    color: $optional-color;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .features-image {
            img {
                border-radius: 50%;
                animation: border-transform-default 10s linear infinite alternate forwards;
            }
        }
    }
    .text-result {
        h3 {
            margin-top: 0;
            margin-bottom: 12px;
            font: {
                size: 25px;
            };
        }
        p {
            margin-bottom: 0;
        }
    }
    .services-details-faq {
        margin-top: 30px;

        .accordion {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: 0;
            };
            .accordion-item {
                display: block;
                background: #f3f3f3;
                border: 0;
                margin-bottom: 10px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .accordion-title {
                padding: 25px 40px 20px 20px;
                color: $black-color;
                position: relative;
                display: block;
                text-transform: capitalize;
                font: {
                    size: 18px;
                    weight: 600;
                };
                i {
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $black-color;
                    font-size: 20px;
                    transition: $transition;
                }
                &.active {
                    i {
                        &::before {
                            content: "\eace";
                            color: $optional-color;
                        }
                    }
                }
            }
            .accordion-content {
                display: none;
                position: relative;
                padding: 18px 20px;
                border-top: 1px solid $optional-color;
                color: $paragraph-color;
    
                &.show {
                    display: block;
                }
            }
        }
    }
}
.services-details-information {
    .services-list {
        padding-left: 0;
        list-style-type: none;
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;

        li {
            a {
                display: block;
                border-bottom: 1px solid #eeeeee;
                padding: 20px 30px;
                position: relative;
                font-size: 15px;
                font-weight: 600;

                &:hover, &.active {
                    background-color: $optional-color;
                    border-color: $optional-color;
                    color: $white-color;
                }
            }
            &:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }
    .download-file {
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;
        padding: 30px;

        h3 {
            margin-bottom: 25px;
            font-size: 22px;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                a {
                    display: block;
                    border-bottom: 1px solid #eeeeee;
                    position: relative;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-size: 14px;
                    font-weight: 600;
                    i {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 22px;
                    }
                    &:hover {
                        color: $optional-color;
                    }
                }
                &:first-child {
                    border-top: 1px solid #eeeeee;
                }
            }
        }
    }
    .services-contact-info {
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;
        padding: 30px;

        h3 {
            margin-bottom: 25px;
            font-size: 22px;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
    
            li {
                margin-bottom: 20px;
                border-bottom: 1px solid #eeeeee;
                position: relative;
                color: $optional-color;
                font-weight: 500;
                font-size: $font-size;
                padding-left: 35px;
                padding-bottom: 20px;
                font-size: 14px;
                span {
                    color: $black-color;
                    display: block;
                    margin-bottom: 8px;
                    font-size: 18px;
                    font-weight: 600;
                }
                a {
                    display: block;
                    color: $optional-color;
                    margin-bottom: 8px;
                    font-size: 14px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        color: $optional-color;
                    }
                }
                .icon {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    color: $optional-color;
                    font-size: 25px;
                }
                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    text-align: center;
    margin-top: 20px;

    .page-numbers {
        width: 35px;
        height: 35px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 36px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;

        &.current, &:hover, &:focus {
            background: $optional-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Portfolio Details Area CSS
=================================================*/
.portfolio-details-image {
    margin-bottom: 30px;
}
.portfolio-details-desc {
    h3 {
        margin-bottom: 14px;
        font-size: 25px;
    }
    .features-text {
        margin-top: 20px;
        margin-bottom: 20px;

        h4 {
            margin-bottom: 12px;
            font-size: 18px;

            i {
                font-size: $font-size;
                margin-right: 4px;
                color: $optional-color;
            }
        }
    } 
    h4 {
        margin-top: 20px;
        font-size: 25px;
    }
}
.portfolio-details-information {
    background-color: $white-color;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top: 3px solid $optional-color;
    padding: 40px 30px;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            position: relative;
            color: $paragraph-color;
            font-weight: 600;
            font-size: 15px;
            padding-left: 35px;
            padding-bottom: 20px;
            span {
                color: $black-color;
                display: block;
                margin-bottom: 8px;
                font-size: 20px;
                font-weight: 600;
            }
            a {
                display: block;
                color: $paragraph-color;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    color: $optional-color;
                }
            }
            .icon {
                position: absolute;
                left: 0;
                top: 0;
                color: $optional-color;
                font-size: 25px;
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: -8px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    color: $black-color;
                    margin-right: 20px;
                    
                    span {
                        display: inline-block;
                        color: $black-color;
                        font-weight: 500;
                    }
                    a {
                        display: inline-block;
                        color: $paragraph-color;
    
                        &:hover {
                            color: $main-color;
                        }
                    }
                    i {
                        color: $main-color;
                        margin-right: 2px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 12px;
                        right: -15px;
                        width: 6px;
                        height: 1px;
                        background: $main-color;
                    }
                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
        h3 {
            font-size: 25px;
            margin: {
                bottom: 15px;
                top: 25px;
            }
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            }
            li {
                margin-bottom: 16px;
                position: relative;
                padding-left: 34px;
                color: $paragraph-color;

                i {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 40px;
                    background-color: #faf5f5;
                    color: $optional-color;
                    transition: $transition;
                    display: inline-block;
                    font-size: 11px;
                    position: absolute;
                    left: 0;
                    top: -2px;
                }
                &:hover {
                    i {
                        background-color: $optional-color;
                        color: $white-color;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $paragraph-color;
            
            span {
                display: inline-block;
                color: $black-color;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }
            a {
                display: inline-block;
                margin-right: 2px;
                font-weight: 500;
                color: $paragraph-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 2px;
                        font-weight: 500;
                        color: $paragraph-color;
                    }
                    a {
                        display: block;
                        color: $optional-color;
                        width: 30px;
                        height: 30px;
                        line-height: 34px;
                        border-radius: 50%;
                        background-color: #eeeef0;
                        text-align: center;
        
                        &:hover {
                            color: $white-color;
                            background-color: $optional-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
}
blockquote, .blockquote {
    background-color: #fafafa;
    padding: 30px !important;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: 3px solid $optional-color;
    border-right: 3px solid $optional-color;
    border-radius: 5px;

    p {
        color: $black-color;
        margin-bottom: 0;
        font-size: 18px !important;
        font-weight: 500;
        font-style: italic;
    }
}
.post-navigation {
    margin-top: 30px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: flex;
        flex-wrap: wrap;

        .nav-previous {
            flex: 0 0 50%;
            max-width: 50%;

            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                    font-size: 18px;
                    position: relative;
                    top: 1px;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;

            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                    font-size: 18px;
                    position: relative;
                    top: 1px;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                font-weight: 600;
            }
        }
    }
}
.comments-area {
    margin-top: 30px;

    .comments-title {
        margin-bottom: 30px;
        font-size: 24px;
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        padding-left: 70px;
        color: $black-color;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px dashed #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 25px 5px;
                border-radius: 30px;
                text-transform: capitalize;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    color: $white-color;
                    background-color: $optional-color;
                    border-color: $optional-color;
                }
            }
        }
    }
    .comment-author {
        font-size: $font-size;
        margin-bottom: 8px;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font-weight: 600;
        }
    }
    .comment-metadata {
        color: $paragraph-color;
        margin-bottom: 10px;
        font-size: 14px;

        a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
                color: $optional-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font-size: 24px;

            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin-top: 10px;
            margin-bottom: 20px;

            .required {
                color: red;
            }
        }
        .comment-form-comment {
            float: left;
            width: 100%;
        }
        label {
            display: none;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: #f4f4f4;
            border: none;
            padding: 1px 0 0 15px;
            height: 60px;
            outline: 0;
            border-radius: 2px;
            color: $black-color;

            &::placeholder {
                color: $paragraph-color;
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-top: 15px;
            margin-bottom: 20px;
            input {
                position: absolute;
                left: 0;
                top: 7.5px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $paragraph-color;
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px 10px;
                display: inline-block;
                cursor: pointer;
                text-transform: capitalize;
                transition: $transition;
                border-radius: 0;
                font-weight: 600;
                border-radius: 5px;
            }
        }
    }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    .widget {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 10px;
            border-bottom: 1px solid #eeeeee;
            font-size: 20px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background: $main-color;
            }
        }
    }
    .widget_search {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 8px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                border-radius: 5px;

                &:focus {
                    border-color: $optional-color;
                }
            }
            button {
                border: none;
                background-color: #eeeeee;
                color: $optional-color;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 5px;
                padding: 0;
                transition: $transition;
                top: 5px;
                font-size: 20px;
                border-radius: 5px;
                cursor: pointer;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 52%;
                    transform: translateY(-52%);
                }
                &:hover, &:focus {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_rainz_posts_thumb {
        position: relative;
        overflow: hidden;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                      //  background-image: url(assets/img/blog/blog-1.jpg);
                    }
                    &.bg2 {
                     //   background-image: url(assets/img/blog/blog-2.jpg);
                    }
                    &.bg3 {
                     //   background-image: url(assets/img/blog/blog-3.jpg);
                    }
                    &.bg4 {
                     //   background-image: url(assets/img/blog/blog-7.jpg);
                    }
                    &.bg5 {
                     //   background-image: url(assets/img/blog/blog-8.jpg);
                    }
                    &.bg6 {
                     //   background-image: url(assets/img/blog/blog-9.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    margin-top: -2px;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font-size: 17px;
                    font-weight: bold;

                    a {
                        display: inline-block;
                        transition: $transition;

                        &:hover {
                            color: $optional-color;
                        }
                    }
                }
            }
        }
    }
    .widget_categories {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: $black-color;
                padding-left: 18px;
                font-size: 15px;
                font-weight: 500;

                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 8px;
                    width: 8px;
                    content: '';
                    border-radius: 50%;
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: $optional-color;
                    }
                }
            }
        }
    }
    .widget_tag_cloud {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .widget-title {
            margin-bottom: 18px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background: #f5f5f5;
            color: $black-color;
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font-weight: 500;
            font-size: 15px !important;
            margin-top: 8px;
            margin-right: 5px;

            &:hover, &:focus {
                color: $white-color;
                background-color: $optional-color;
            }
        }
    }
    .price_list_widget {
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .form-group {
            .form-control {
                display: block;
                width: 100%;
                height: 50px;
                outline: 0;
                background-color: $white-color;
                border: 1px solid #f1f1f1;
                border-radius: 3px;
                box-shadow: none;
                padding: 0 12px;
                transition: $transition;

                &:focus {
                    outline: 0;
                    background-color: $white-color;
                    border-color: $optional-color;
                    box-shadow: none;
                }
            }
        }
    }
    .widget_popular_products {
        position: relative;
        overflow: hidden;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding: 25px;

        .widget-title {
            margin-bottom: 30px;
        }
        .item {
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: left;
                height: 110px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 110px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 110px;
                    height: 110px;
                    display: inline-block;
                    border-radius: 2px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                    //    background-image: url(assets/img/shop/shop1.jpg);
                    }
                    &.bg2 {
                     //   background-image: url(assets/img/shop/shop2.jpg);
                    }
                    &.bg3 {
                      //  background-image: url(assets/img/shop/shop3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    text-transform: uppercase;
                    margin-top: -5px;
                    margin-bottom: 6px;
                    font-weight: 500;
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font-weight: 500;
                    font-size: 18px;

                    a {
                        display: inline-block;
                        &:hover {
                            color: $optional-color;
                        }
                    }
                }
                .rating {
                    margin-top: 8px;

                    i {
                        color: #f6b500;
                        display: inline-block;
                        margin-right: -2px;
                    }
                }
            }
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.rainz-grid-sorting {
    margin-bottom: 40px;

    .result-count {
        p {
            .count {
                font-weight: 700;
                color: $black-color;
            }
        }
    }
    .ordering {
        text-align: right;

        label {
            display: inline-block;
            margin-bottom: 0;
            color: $optional-color;
            margin-right: 5px;
            font-weight: 600;
        }
        .nice-select {
            display: inline-block;
            float: unset;
            width: 215px;
            background: #f8f8f8;
            border-color: #eeeeee;
            color: $black-color;
            transition: $transition;
            padding: 0 0 0 12px;
            height: 45px;
            line-height: 45px;
            font-size: 15px;
            font-weight: 500;

            .list {
                background-color: $white-color;
                border-radius: 5px;
                box-shadow: 0 10px 30px rgba(0,0,0,.2);
                list-style-type: none;
                border: none;
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                padding-left: 0;
                padding-top: 10px;
                padding-bottom: 10px;

                .option {
                    line-height: 38px;
                    min-height: 38px;
                    color: $black-color;
                    position: relative;
                    transition: $transition;
                    padding-left: 15px;
                    padding-right: 25px;
                    font-size: 15px;
                    font-weight: 500;

                    &:hover {
                        background-color: $optional-color !important;
                        color: $white-color;
                    }
                }
            }
            &:after {
                border-color: $black-color;
                height: 8px;
                width: 8px;
                margin-top: -5px;
            }
            &:hover {
                border-color: $optional-color;
                background-color: transparent;
            }
        }
    }
}
.single-products-box {
    position: relative;
    margin-bottom: 30px;
    transition: $transition;

    .products-image {
        text-align: center;
        position: relative;
        overflow: hidden;

        a {
            position: relative;
            display: block;

            img {
                transition: $transition;
            }
        }
    }
    .products-content {
        margin-top: 25px;
        position: relative;

        h3 {
            margin-bottom: 0;
            font-size: 22px;

            a {
                display: inline-block;
                color: $black-color;

                &:hover {
                    color: $optional-color;
                }
            }
        }
        .price {
            margin-top: 15px;
            transition: $transition;
            font-weight: 600;
            color: $optional-color;

            .old-price {
                text-decoration: line-through;
                color: #999999;
                font-weight: 500;
                margin-right: 5px;
            }
        }
        .star-rating {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #f49f0b;

            i {
                display: inline-block;
            }
        }
        .add-to-cart {
            position: absolute;
            left: 0;
            bottom: 0;
            text-transform: capitalize;
            transition: $transition;
            display: inline-block;
            opacity: 0;
            visibility: hidden;
            transform: translateX(20px);
            font-weight: 600;
            font-size: 15px;

            &:hover {
                color: $optional-color;
            }
        }
    }
    &:hover {
        .products-content {
            .price {
                opacity: 0;
                visibility: hidden;
            }
            .add-to-cart {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
            }
        }
        .products-image {
            a {
                img {
                    transform: scale(1.07);
                }
            }
        }
    }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 20px;
                    border: none;
                    white-space: nowrap;
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: .6s;
                    white-space: nowrap;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-left: 0;
                    padding-right: 0;
                    border-color: #eaedff;
                    border-left: none;
                    border-right: none;

                    &.product-thumbnail {
                        a {
                            display: block;
                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            color: #666666;
                            font-weight: 500;
                            display: inline-block;
                            text-decoration: none;

                            &:hover {
                                color: $optional-color;
                            }
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: $optional-color;
                            float: right;
                            position: relative;
                            top: -1px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    

                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 48px;
                                transition: .6s;

                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $optional-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $optional-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                outline: 0;
                                box-shadow: none;
                                font-weight: 600;

                                &::placeholder {
                                    color: $optional-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;
}
.cart-totals {
    background: $white-color;
    padding: 30px;
    max-width: 750px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: 50px auto 0;

    h3 {
        margin-bottom: 25px;
        font-size: 25px;
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 20px 15px;
            color: $black-color;
            overflow: hidden;
            font-weight: 600;

            &:first-child {
                border-bottom: none;
            }
            &:last-child {
                border-top: none;
            }
            b {
                font-weight: 500;
            }
            span {
                float: right;
                color: $paragraph-color;
                font-weight: 500;
            }
        }
    }
    .default-btn {
        position: relative;
        z-index: 1;
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 18px 20px 15px;
    margin-bottom: 65px;
    border-top: 3px solid $black-color;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: -35px;
        width: 100%;
        height: 1px;
        background: #eeeeee;
    }
    i {
        color: $black-color;
        margin-right: 2px;
        font-size: 18px;
        color: $optional-color;
        position: relative;
        top: 2px;
    }
    span {
        display: inline-block;
        font-weight: 600;
        color: $black-color;
        font-size: 14px;

        a {
            display: inline-block;
            color: $black-color;
            text-decoration: none;

            &:hover, &:focus {
                color: $optional-color;
            }
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font-size: 25px;
        font-weight: bold;

        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 8px;
            font-size: $font-size;
            font-weight: 500;

            .required {
                color: $black-color;
            }
        }
        .nice-select {
            float: unset;
            line-height: 60px;
            color: #666666;
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 0;

            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;

                .option {
                    transition: $transition;
                    padding-left: 20px;
                    padding-right: 20px;

                    &:hover {
                        background-color: $optional-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
        .form-control {
            height: 60px;
            outline: 0;
            box-shadow: none;

            &:focus {
                border-color: $optional-color;
                outline: 0;
                box-shadow: none;
            }
        }
        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font-weight: 500;
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font-size: 25px;
        font-weight: bold;

        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        font-weight: 600;
                        padding: {
                            left: 20px;
                            top: 25px;
                            right: 20px;
                            bottom: 20px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: #666666;
                        border-color: #eaedff;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 20px;
                            bottom: 20px;
                        }
                        &.product-name {
                            a {
                                color: #666666;
                                display: inline-block;
                                text-decoration: none;
                                font-weight: 500;
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: #666666;
                                font-weight: 500;
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;

        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 28px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font-size: $font-size;
                            font-weight: 600;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 4px;
                                width: 20px;
                                height: 20px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $optional-color;
                                position: absolute;
                                top: 8px;
                                left: 4px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            display: block;
            margin-top: 25px;
            position: relative;
            z-index: 1;
            text-align: center;
        }
    }
}

/*================================================
Product Details Area CSS
=================================================*/
.product-details-image {
   // background-image: url(assets/img/shop/shop1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}
.product-details-desc {
    padding: 15px;
    padding-left: 0;
    padding-right: 0;

    h3 {
        margin-bottom: 18px;
        font-size: 25px;
    }
    .price {
        margin-bottom: 15px;
        color: #000000;
        font-size: 15px;
        font-weight: 600;

        .old-price {
            text-decoration: line-through;
            color: #828893;
        }
    }
    p {
        margin-bottom: 0;
    }
    .product-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;

            i {
                color: #ffba0a;
            }
        }
        .rating-count {
            display: inline-block;
            color: #000000;
            border-bottom: 1px solid #000000;
            line-height: initial;

            &:hover {
                color: $optional-color;
                border-color: $optional-color;
                text-decoration: none;
            }
        }
    }
    .product-add-to-cart {
        margin-top: 20px;

        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 50px;
                height: 100%;
                line-height: 55px;
                transition: $transition;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $optional-color;
                }
            }
            input {
                height: 50px;
                color: #000000;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font-size: $font-size;
                font-weight: 600;

                &::placeholder {
                    color: #000000;
                }
            }
        }
        .default-btn {
            position: relative;
            padding: 12px 30px;
            background-color: $optional-color;
            color: $white-color;
            border: 1px solid $optional-color;
            cursor: pointer;

            i {
                margin-right: 2px;
            }
            &:hover {
                background-color: $white-color;
                color: $optional-color;
                transition: $transition;
            }
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: #000000;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: .6s;
                    }
                }
                &:last-child {
                    position: relative;
                    top: 2px;
                    padding-left: 4px;
                    color: #666666;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $optional-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $optional-color;
            border-color: $optional-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 15px;
            }
        }
        .btn-light {
            background-color: #f2f2f2;
            border: none;
            padding: 13px 25px 10px 25px;
            transition: .6s;
            font-weight: 600;
            display: block;
            width: 100%;
            
            &:hover {
                background-color: $optional-color;
                color: $white-color;
            }
        }
    }
    .products-share {
        margin-top: 30px;

        .social {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                display: inline-block;

                span {
                    display: inline-block;
                    margin-right: 3px;
                    font-weight: 700;
                    position: relative;
                    top: -2px;
                }
                a {
                    display: block;
                    width: 35px;
                    height: 35px;
                    line-height: 36px;
                    border-radius: 50%;
                    background-color: $optional-color;
                    color: $white-color;
                    border: 1px solid $optional-color;
                    text-align: center;
                    font-size: 18px;
                    margin-left: 4px;
    
                    &:hover, &:focus {
                        color: $main-color;
                        background-color: transparent;
                    }
                    &.facebook {
                        background-color: #3b5998;
                        border-color: #3b5998;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #3b5998;
                            background-color: transparent;
                        }
                    }
                    &.twitter {
                        background-color: #1da1f2;
                        border-color: #1da1f2;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #1da1f2;
                            background-color: transparent;
                        }
                    }
                    &.linkedin {
                        background-color: #007bb5;
                        border-color: #007bb5;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #007bb5;
                            background-color: transparent;
                        }
                    }
                    &.instagram {
                        background-color: #c13584;
                        border-color: #c13584;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #c13584;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.products-details-tab {
    margin-top: 50px;

    .tabs {
        list-style-type: none;
        margin-bottom: -1px;
        padding-left: 0;

        li {
            display: inline-block;
            line-height: initial;
            margin-right: 5px;

            a {
                display: inline-block;
                position: relative;
                text-transform: capitalize;
                color: $black-color;
                border: 1px dashed #eeeeee;
                text-decoration: none;
                font-size: $font-size;
                font-weight: 500;
                padding: {
                    top: 14px;
                    bottom: 14px;
                    right: 30px;
                    left: 30px;
                }
                &:hover, &:focus {
                    color: $white-color;
                    background-color: $optional-color;
                    border-color: $optional-color;
                }
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $optional-color;
                    border-color: $optional-color;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tab_content {
        border: 1px dashed #eeeeee;
        padding: 30px;

        .tabs_item {
            display: none;
            
            &:first-child {
                display: block;
            }
            .products-details-tab-content {
                p {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 24px;
                        weight: 700;
                    }
                }
                .product-review-form {
                    .review-title {
                        position: relative;

                        .rating {
                            display: inline-block;

                            i {
                                color: #ffba0a;
                                font-size: 14px;
                            }
                        }
                        p {
                            margin-bottom: 0;
                            display: inline-block;
                            padding-left: 5px;
                            line-height: initial;
                        }
                        .default-btn {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            padding: 8px 20px;
                            background-color: $optional-color;
                            color: $white-color;
                            transition: $transition;
                            font-size: 15px;
                            border: 1px solid $optional-color;

                            &:hover {
                                background-color: $white-color;
                                color: $optional-color;
                                text-decoration: none;
                            }
                        }
                    }
                    .review-comments {
                        margin-top: 35px;

                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 200px;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;

                            .rating {
                                .fas.fa-star {
                                    color: #ffba0a;
                                }
                                i {
                                    font-size: 14px;
                                    color: #ffba0a;
                                }
                            }
                            h3 {
                                font-size: 18px;
                                margin: {
                                    top: 10px;
                                    bottom: 10px;
                                }
                            }
                            span {
                                margin-bottom: 10px;
                                font-size: 13px;
                                display: block;

                                strong {
                                    font-weight: 600;
                                }
                            }
                            p {
                                margin-bottom: 0;
                            }
                            .review-report-link {
                                position: absolute;
                                right: 0;
                                color: #666666;
                                top: 40px;
                                text-decoration: underline;
                                font: {
                                    weight: 500;
                                    size: 15px;
                                }
                                &:hover {
                                    color: $optional-color;
                                }
                            }
                        }
                    }
                    .review-form {
                        margin-top: 30px;

                        h3 {
                            margin-bottom: 20px;
                        }
                        form {
                            max-width: 100%;

                            .form-group {
                                margin-bottom: 15px;

                                .form-control {
                                    height: 55px;
                                    background-color: #eeeeee;
                                    border: 1px solid #eeeeee;
                                    transition: $transition;
                                    color: $black-color;

                                    &:focus {
                                        background-color: transparent;
                                        border: 1px solid $optional-color;
                                        outline: 0;
                                        box-shadow: none;
                                    }
                                }
                                textarea.form-control {
                                    padding-top: 15px;
                                    height: auto;
                                }
                            }
                        }
                        .default-btn {
                            cursor: pointer;
                            position: relative;
                            z-index: 1;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}
.related-shop {
    margin-top: 35px;

    h4 {
        font-size: 25px;
        margin-bottom: 25px;
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-item {
    img {
        width: auto !important;
        margin: auto;
        max-width: 150px;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: #f5f3f3;
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 25px;
    }
    p {
        color: #666666;
        margin: {
            bottom: 0;
        };
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin-top: 25px;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                display: block;
                width: 35px;
                height: 35px;
                line-height: 40px;
                border-radius: 50%;
                background-color: $main-color;
                color: $white-color;
                border: 1px solid $main-color;
                text-align: center;
                font-size: 18px;

                &:hover, &:focus {
                    color: $main-color;
                    background-color: $optional-color;
                }
                &.facebook {
                    background-color: #3b5998;
                    border-color: #3b5998;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &.twitter {
                    background-color: #1da1f2;
                    border-color: #1da1f2;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &.linkedin {
                    background-color: #007bb5;
                    border-color: #007bb5;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
                &.pinterest {
                    background-color: #c13584;
                    border-color: #c13584;
                    color: $white-color;

                    &:hover, &:focus {
                        color: $white-color;
                        background-color: $optional-color;
                    }
                }
            }
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 25px;
        color: $optional-color;
    }
    .quick-links {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 16px;
            font-size: 15px;

            a {
                display: inline-block;
                color: $black-color;
                font-weight: 500;
                position: relative;

                &:hover {
                    color: $optional-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 22px;
            color: $black-color;
            position: relative;
            padding-left: 35px;

            i {
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 22px;
                color: $optional-color;
            }
            span {
                display: block;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 5px;
                color: $black-color;
            }
            a {
                display: inline-block;
                color: $black-color;
                font-weight: 400;
                position: relative;
                font-size: 15px;

                &:hover {
                    color: $optional-color;

                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Copy Right Area CSS
=================================================*/
.copyright-area {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #0f171f;

    .copyright-area-content {
        p {
            color: $white-color;

            a {
                display: inline-block;
                font-weight: 600;
                color: $optional-color;

                &:hover {
                    color: $white-color;
                }
            }
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            text-align: right;

            li {
                display: inline-block;
                position: relative;
                margin: {
                    left: 10px;
                    right: 10px;
                };
                a {
                    display: inline-block;
                    color: $white-color;

                    &:hover {
                        color: $optional-color;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    right: -10px;
                    width: 1px;
                    height: 14px;
                    background: $main-color;
                }
                &:last-child {
                    margin-right: 0;
    
                    &::before {
                        display: none;
                    }
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: $white-color;
    background-color: $black-color;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 25px;
    transition: .9s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    
    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 30px;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 20px;
    }
    &:hover {
        background: $optional-color;
        color: $white-color;
        transition: $transition;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }
}